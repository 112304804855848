import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {TabsPage} from './tabs/tabs.page';
import {AuthGuard} from './shared';

const routes: Routes = [
    {
        path: 'login',
        loadChildren: './pages/login/login.module#LoginPageModule'
    },
    {
        path: 'register',
        loadChildren: './pages/register/register.module#RegisterPageModule'
    },
    {
        path: 'reset',
        loadChildren: './modals/user-reset/user-reset.module#UserResetPageModule'
    },
    {
        path: 'tabs',
        component: TabsPage,
        children: [
            {
                path: 'overview',
                children: [
                    {
                        path: '',
                        loadChildren: './pages/overview/overview.module#OverviewPageModule',
                        canActivate: [AuthGuard]
                    }
                ]
            },
            {
                path: 'statistic',
                children: [
                    {
                        path: '',
                        loadChildren: './pages/statistic/statistic.module#StatisticPageModule',
                        canActivate: [AuthGuard]
                    }
                ]
            },
            {
                path: 'more',
                children: [
                    {
                        path: '',
                        loadChildren: './pages/more/more.module#MorePageModule'
                    }
                ]
            }
        ]
    },
    // Otherwise redirect to first
    {
        path: '**',
        redirectTo: 'login'
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {}
