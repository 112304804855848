import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

import {Inverter} from '../models/inverter';
import {environment} from '../../environments/environment';
import {User} from 'firebase';

@Injectable({
    providedIn: 'root'
})
export class UserHttpService {

    constructor(private http: HttpClient) {}

    public getUser(uid: string): Observable<User> {
        return this.http.get<User>(`${environment.appApi.url}/users/${uid}`);
    }

    /** Get configured inverters for user */
    public getUserInverters(uid: string): Observable<Inverter[]> {
        return this.http.get<Inverter[]>(`${environment.appApi.url}/users/${uid}/inverters`);
    }

    /** Update the inverters-configuration for given user. */
    public updateInverters(uid: string, inverters: Inverter[]): Observable<void> {
        this.log(`Update inverter-configurations for user (${uid})`);
        return this.http.put<void>(`${environment.appApi.url}/users/${uid}/inverters`, inverters);
    }

    /** Update the last-sign-in property for given user. */
    public updateLastSignIn(uid: string, lastSignInAt: string): Observable<void> {
        this.log(`Update last-sign-in timestamp for user (${uid})`);
        return this.http.put<void>(`${environment.appApi.url}/users/${uid}/lastSignIn`, lastSignInAt);
    }

    private log(message: string): void {
        if (!environment.production) {
            console.log(`[User service]: ${message}`);
        }
    }

}
