// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    appApi: {
        url: 'https://api.panta10.com'
    },
    firebaseConfig: {
        apiKey: 'AIzaSyBrYntJX9TND_UEGr-jNcaL4zn7feIQ5uM',
        authDomain: 'panta-10-dev.firebaseapp.com',
        databaseURL: 'https://panta-10-dev.firebaseio.com',
        projectId: 'panta-10-dev',
        storageBucket: 'panta-10-dev.appspot.com',
        messagingSenderId: '859057419273',
        appId: '1:859057419273:web:10130528e6adac0e'
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
