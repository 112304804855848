import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {HttpErrorInterceptor} from './http-error-interceptor';
import {HttpProfilerInterceptor} from './http-profiler-interceptor';

/**
 * Examples:
 * -) https://angular.io/guide/http#intercepting-requests-and-responses
 * -) https://medium.com/@swapnil.s.pakolu/angular-interceptors-multiple-interceptors-and-6-code-examples-of-interceptors-59e745b684ec
 * -) https://blog.angularindepth.com/top-10-ways-to-use-interceptors-in-angular-db450f8a62d6
 */
export const interceptorProviders = [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpProfilerInterceptor,
        multi: true
    },
    // {
    //     provide: HTTP_INTERCEPTORS,
    //     useClass: HttpLoaderInterceptor,
    //     multi: true
    // },
    // {
    //     provide: HTTP_INTERCEPTORS,
    //     useClass: HttpRetryInterceptor,
    //     multi: true
    // },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpErrorInterceptor,
        multi: true
    }

    // Add others interceptors, like the existing once:
    // Authentication / Session
    // Request Format (XML to JSON)
    // Caching
    // Headers
    // Fake backend

];
