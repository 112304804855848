import {Injectable} from '@angular/core';

import {AngularFireAuth} from '@angular/fire/auth';
import * as firebase from 'firebase';

import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {UserHttpService} from '../../services/user-http.service';

/**
 * The service is used to login & logout users in the app.
 */
@Injectable({
    providedIn: 'root'
})
export class AuthService {

    // The current logged in user where components can subscribe to, to get
    // notified about changes
    public currentUser: Observable<firebase.User> = null;

    public currentErrorSubject: BehaviorSubject<string>;

    // The behavior-subject which keep a reference
    private currentUserSubject: BehaviorSubject<firebase.User>;

    constructor(private firebaseAuth: AngularFireAuth,
                private userService: UserHttpService) {
        this.currentErrorSubject = new BehaviorSubject<string>(null);
        this.currentUserSubject = new BehaviorSubject<firebase.User>(null);
        this.currentUser = this.currentUserSubject.asObservable();

        this.firebaseAuth.authState.subscribe(user => {
            if (user) {
                this.log(`Re-use previous logged in user (${user.email})`);
            }
            this.currentUserSubject.next(user);
        });
    }

    /**
     * Sign-up user with specified arguments.
     *
     * @param email an e-mail address
     * @param password a password in plain-text
     */
    signUp(email: string, password: string) {
        // Create user at Firebase
        this.firebaseAuth.auth.createUserWithEmailAndPassword(email, password)
            .then(userCredential => {
                this.log(`User (${userCredential.user.email}) signed up`);

                // Notify listeners
                this.currentErrorSubject.next(null);
                this.currentUser = this.firebaseAuth.authState;
            })
            .catch(err => {
                this.log(`User sign up FAILED: ${err.code}`);
                this.currentErrorSubject.next(err.code);
                this.currentUser = this.firebaseAuth.authState;
            });
    }

    /**
     * Login user with specified arguments.
     *
     * @param email an e-mail address
     * @param password a password in plain-text
     */
    signIn(email: string, password: string) {
        // Sign-in at Firebase
        this.firebaseAuth.auth.signInWithEmailAndPassword(email, password)
            .then(userCredential => {
                const user = userCredential.user;

                this.log(`User (${user.email}) logged in`);

                // Notify listeners
                this.currentErrorSubject.next(null);
                this.currentUserSubject.next(userCredential.user);

                // Update service
                this.userService.updateLastSignIn(user.uid, user.metadata.lastSignInTime).subscribe();
            })
            .catch(err => {
                this.log(`User sign in FAILED: ${err.code}`);
                this.currentErrorSubject.next(this.mapErrorCode(err.code));
                this.currentUserSubject.next(null);
            });
    }

    /**
     * Logout user.
     */
    signOut() {
        // Sign-out at Firebase
        this.firebaseAuth.auth.signOut()
            .then(() => {
                this.log(`User signed out`);
            })
            .catch(err => {
                this.log(`User sign out FAILED: ${err.code}`);
                this.currentErrorSubject.next(err.code);
            })
            .finally(() => {
                this.currentUserSubject.next(null);
            });
    }

    /**
     * Change password of current signed-in user.
     *
     * @param passwordNew: string
     */
    changePassword(passwordNew: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (!passwordNew) {
                reject();
            }

            this.currentUserSubject.getValue().updatePassword(passwordNew)
                .then(() => {
                    this.log(`Password successfully changed`);
                    resolve();
                })
                .catch(err => {
                    this.log(`Password change FAILED: ${err.code}`);
                    this.currentErrorSubject.next(this.mapErrorCode(err.code));
                    reject()
                });
        });
    }

    /**
     * Change password
     *
     * @param confirmationCode string
     * @param passwordNew string
     */
    resetPassword(email: string) {
        this.firebaseAuth.auth.sendPasswordResetEmail(email)
            .then(() => {
                this.log(`Password reset e-mail sent`);
                this.currentErrorSubject.next(null);
            })
            .catch(err => {
                this.log(`Password reset e-mail FAILED: ${err.code}`);
                this.currentErrorSubject.next(this.mapErrorCode(err.code));
            });
    }

    /**
     * Change password
     *
     * @param confirmationCode string
     * @param passwordNew string
     */
    confirmResetPassword(confirmationCode: string, passwordNew: string) {
        this.firebaseAuth.auth.confirmPasswordReset(confirmationCode, passwordNew)
            .then(() => {
                this.log(`Password successfully resetted`);
            })
            .catch(err => {
                this.log(`Password reset FAILED: ${err.code}`);
            });
    }

    mapErrorCode(errorCode: string): string | null {
         if (errorCode === 'auth/user-not-found') {
            return 'Fehler: ihre E-Mail Adresse ist nicht registriert';
        } else if (errorCode === 'auth/wrong-password') {
            return 'Fehler: ihr Passwort ist falsch';
        } else if (errorCode === 'auth/email-already-in-use') {
            return 'Fehler: ihre E-Mail Adresse ist bereits in Verwendung';
        } else if (errorCode === 'auth/weak-password') {
             return 'Fehler: ihr Passwort ist zu kurz';
        } else {
            console.warn(`Auth FAILED: ${errorCode}`);
            return 'Unerwarteter Fehler: überprüfen sie ihre Eingaben';
        }
    }

    public get currentUserValue() {
        return this.currentUserSubject.value;
    }

    private log(message: string): void {
        if (!environment.production) {
            console.log(`[Auth service]: ${message}`);
        }
    }
}
